<template>
  <div class="league-container">
    <div class="title">意向提交<span>/INTENTION SUBMIT</span></div>
    <div class="text">
      <input v-model="form.name" placeholder="您的姓名">
    </div>
    <div class="text">
      <input v-model="form.mobile" placeholder="您的电话">
    </div>
    <div class="text">
      <input v-model="form.region" placeholder="意向区域：XX省XX市XX区或XX县">
    </div>
    <div class="textarea">
      <textarea v-model="form.remark" placeholder="我要留言" />
    </div>
    <div class="button">
      <div class="submit" @click="submit">
        提交
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: "",
        mobile: "",
        region: "",
        remark: "",
      },
    };
  },
  methods: {
    submit() {
      this.$api("/official/page/join_now_form", this.form).then(() => {
        // console.log(123123);
        this.$router.push({ path: "/mobile/result" });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.league-container {
  margin-bottom: 468px;
  margin-left: 66px;
  margin-right: 66px;
  width: auto;
  background: #ffffff;
  border-radius: 108px;
  padding: 96px 0 111px 0;

  .title {
    padding-bottom: 58px;
    font-size: 73px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 100px;

    text-align: center;

    span {
      color: #ff6f1b;
    }
  }

  .text {
    position: relative;
    background: #fff5ef;
    border-radius: 158px;

    margin-top: 38px;

    display: flex;
    align-items: center;

    &::after {
      content: "*";
      font-size: 54px;
      color: #ff4d00;
      position: absolute;
      right: 80px;
      top: 75px;
    }

    input {
      margin: 46px 109px 46px 69px;
      flex: 1;
      // width: 570px;
      // height: 60px;
      background: none;
      border-radius: 41px;
      outline: none;
      border: none;

      text-indent: 30px;

      font-size: 50px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333;
      line-height: 81px;

      &::-webkit-input-placeholder {
        /* WebKit browsers */
        color: #333333;
        font-size: 50px;
      }

      &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #333333;
        font-size: 50px;
      }

      &::-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: #333333;
        font-size: 50px;
      }
    }
  }

  .textarea {
    margin-top: 77px;
    flex: 1;
    display: flex;
    height: 461px;
    textarea {
      flex: 1;
      border: none;
      outline: none;
      resize: none;
      padding: 58px 69px;
      background: #fff5ef;
      border-radius: 54px;

      &::-webkit-input-placeholder {
        /* WebKit browsers */
        color: #333333;
        font-size: 50px;
      }

      &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #333333;
        font-size: 50px;
      }

      &::-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: #333333;
        font-size: 50px;
      }
    }
  }

  .button {
    display: flex;
    justify-content: center;
    margin-top: 320px;

    .submit {
      width: 864px;
      height: 173px;
      background: linear-gradient(180deg, #ffa83a 0%, #ff6f1b 100%);
      border-radius: 86px;

      font-size: 58px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 81px;

      display: flex;
      align-items: center;
      justify-content: center;

      border: 1px solid #ffffff;
      // transition: 0.5s;

      &:active {
        border: 1px solid #ff6f1b;
        background: none;
        color: #ff6f1b;
      }
    }
  }
}
</style>